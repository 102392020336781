import { EDataLayerActionGroups, EDataLayerActions } from '@mtsdengi/common'

import {
  EEvents,
  EDataLayerEventCategoriesLocal,
  TDataLayerObjLocal,
  EDataLayerEventsLocal,
  EDataLayerEventLabel,
} from '@shared/types'

export const dataLayerEvents = (event: EEvents, eventsObj: TDataLayerObjLocal): TDataLayerObjLocal => {
  switch (event) {
    case EEvents.PAGE_NOT_FOUND_VIEW: {
      return {
        ...eventsObj,
        event: EDataLayerEventsLocal.MTS_DENGI_MAIN,
        eventCategory: EDataLayerEventCategoriesLocal.OSHIBKI,
        eventAction: EDataLayerActions.ERROR_SHOW,
        eventLabel: EDataLayerEventLabel.NOT_FOUND,
        actionGroup: EDataLayerActionGroups.NON_INTERACTIONS,
        filterName: '404',
        pageType: null,
      }
    }
    default: {
      return undefined
    }
  }
}
