import React, { FC, useCallback } from 'react'
import { CardFeature, TCardFeature } from '@mtsdengi/common'
import { mergeProps } from '@shared/lib'
import { TObservedCardFeature } from './types'
import { Styled } from './styled'

export const ObservedCardFeature: FC<TObservedCardFeature> = ({
  commonProps,
  cardProps,
  onAfterVisibleCard,
  onClickCardButton,
}) => {
  const allProps = mergeProps<TCardFeature>(commonProps, cardProps)
  const onAfterVisibleCardHandler = useCallback(() => {
    onAfterVisibleCard?.({
      headingText: allProps.heading.text,
      buttonText: allProps.button.text,
    })
  }, [allProps.button.text, allProps.heading.text, onAfterVisibleCard])

  return (
    <Styled.ObservedWrapper onAfterVisible={onAfterVisibleCardHandler}>
      <CardFeature {...allProps} onClickButton={onClickCardButton} />
    </Styled.ObservedWrapper>
  )
}
