import { GetStaticProps } from 'next'
import { getCommonRequest, TLayout } from '@mtsdengi/common'
import { REVALIDATE_TIME } from '@shared/config'
import { TNotFoundPageData } from '../types'

export const getStaticPropsNotFoundPage: GetStaticProps<TNotFoundPageData> = async () => {
  const [
    { data: layout },
    {
      data: { meta = {}, ...pageData },
    },
  ] = await Promise.all([
    getCommonRequest<TLayout>('/mtsdengi/header-footer'),
    getCommonRequest<TNotFoundPageData>('/mtsdengi/not-found/'),
  ])

  return {
    props: {
      meta,
      layout,
      ...pageData,
    },
    revalidate: REVALIDATE_TIME,
  }
}
