import styled from 'styled-components'
import { Observed } from '@mtsdengi/common'

const ObservedWrapper = styled(Observed)`
  height: 100%;
`

export const Styled = {
  ObservedWrapper,
}
