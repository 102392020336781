import React, { FC, useEffect } from 'react'
import { useDataLayer, Layout, CardFeature } from '@mtsdengi/common'
import { EEvents } from '@shared/types'
import { dataLayerEvents } from './lib'
import { TNotFoundPageData } from './types'

export const NotFoundPage: FC<TNotFoundPageData> = ({ layout, ...pageData }) => {
  const { dataLayerDispatcher } = useDataLayer(dataLayerEvents)

  useEffect(() => {
    dataLayerDispatcher(EEvents.PAGE_NOT_FOUND_VIEW)
  }, [dataLayerDispatcher])

  return (
    <Layout {...layout}>
      <CardFeature {...pageData} />
    </Layout>
  )
}
