import { EDataLayerEvents, EDataLayerEventCategories, TDataLayerObj, EDataLayerPageType } from '@mtsdengi/common'

export enum EEvents {
  PAGE_NOT_FOUND_VIEW = 'notFoundView',
  MAIN_PAGE_VIEW = 'mainView',
  BANNER_CLICK = 'banner_click',
  BANNER_SHOW = 'banner_show',
  GET_EVENT = 'get_event',
  GET_NAMES = 'get_names',
  WIDGET_CLICK = 'widget_click',
  WIDGET_SHOW = 'widget_show',
}

export enum EDataLayerEventsLocal {
  MTS_DENGI_MAIN = 'mtsDengiMain',
}

export enum EDataLayerPageTypeLocal {
  MAIN = 'main',
}

export enum EDataLayerEventCategoriesLocal {
  MTS_DENGI = 'mtsDengi',
  OSHIBKI = 'oshibki',
  ADVERTISING = 'advertising',
  WIDGET = 'widget',
}

export enum EDataLayerEventLabel {
  MAIN_PAGE = 'main_page',
  NOT_FOUND = 'not_found',
}

export type TDataLayerObjLocal = Omit<TDataLayerObj, 'event' | 'pageType' | 'eventCategory'> & {
  event: EDataLayerEventsLocal | EDataLayerEvents
  pageType: EDataLayerPageTypeLocal | EDataLayerPageType
  eventCategory: EDataLayerEventCategories | EDataLayerEventCategoriesLocal
}
