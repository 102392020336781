import React, { FC, useEffect, useState } from 'react'
import { MtsDengiCarousel } from '@mtsdengi/ui-kit'
import { mergeProps } from '@shared/lib'
import { TActiveSlideInfo, THomeCarousel } from '../types'
import { HomeCarouselSlide } from './slide'

export const HomeCarousel: FC<THomeCarousel> = ({
  commonSlideProps,
  slides,
  onChangeSlide,
  onClickCarouselButton,
  slideGradientUrl,
  ...carouselProps
}) => {
  const [activeSlide, setActiveSlide] = useState<TActiveSlideInfo>(null)

  useEffect(() => {
    if (activeSlide) {
      onChangeSlide?.(activeSlide)
    }
  }, [activeSlide, onChangeSlide])

  return (
    <MtsDengiCarousel {...carouselProps}>
      {slides.map((slide, i) => (
        <HomeCarouselSlide
          id={slide.id}
          key={slide.id}
          {...mergeProps(commonSlideProps, slide)}
          position={i + 1}
          slideGradientUrl={slideGradientUrl}
          onChangeSlide={setActiveSlide}
          onClickButton={onClickCarouselButton}
        />
      ))}
    </MtsDengiCarousel>
  )
}
