import styled, { css } from 'styled-components'
import { theme } from '@mtsdengi/ui-kit'
import { SmartText, unAuthTheme } from '@mtsdengi/common'
import { DEFAULT_NUMBER_ADVANTAGES } from './constants'

const Wrapper = styled.div<{ numberAdvantages: number }>`
  display: grid;
  z-index: 2;
  gap: ${theme.spacings.xs}px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 25px ${theme.spacings.xs}px;

  @media (min-width: ${unAuthTheme.breakpoints.tablet}px) {
    ${({ numberAdvantages }) =>
      numberAdvantages > DEFAULT_NUMBER_ADVANTAGES &&
      css`
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `}
    gap: ${theme.spacings.md}px;
    padding: ${unAuthTheme.spacings.md4}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 72px ${unAuthTheme.spacings.md4}px;
  }

  @media (min-width: ${theme.breakpoints.lg}px) {
    padding: 72px ${theme.spacings.xl2}px;
  }
`

const Title = styled.div`
  @media (min-width: ${theme.breakpoints.tablet}px) {
    margin-bottom: ${theme.spacings.xs5}px;
  }
`

const AdvantageDescription = styled(SmartText)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Styled = { AdvantageDescription, Title, Wrapper }
