import React, { FC } from 'react'
import { theme } from '@mtsdengi/ui-kit'
import { PremiumBlock, SmartText } from '@mtsdengi/common'
import { Styled } from './styled'
import { TPremiumProps } from './types'

export const Premium: FC<TPremiumProps> = ({ advantages, ...premiumBlockProps }) => (
  <PremiumBlock {...premiumBlockProps}>
    <Styled.Wrapper numberAdvantages={advantages.length}>
      {advantages.map(({ id, title, description }) => {
        const convertedTitle =
          typeof title === 'string' ? (
            <SmartText
              fontFamily={theme.fonts.font_family_wide}
              fontWeightMob={theme.fonts.font_weights.medium}
              fontSizeMob={theme.fonts.font_sizes.header.md}
              fontSizeTablet={theme.fonts.font_sizes.header.promo2}
              lineHeightMob={theme.fonts.line_heights.header.sm1}
              lineHeightTablet={theme.fonts.line_heights.header.xl}
            >
              {title}
            </SmartText>
          ) : (
            <>
              <SmartText
                fontFamily={theme.fonts.font_family_wide}
                fontWeightMob={theme.fonts.font_weights.medium}
                fontSizeMob={theme.fonts.font_sizes.header.md}
                fontSizeTablet={theme.fonts.font_sizes.header.promo2}
                lineHeightMob={theme.fonts.line_heights.header.sm1}
                lineHeightTablet={theme.fonts.line_heights.header.xl}
                as="span"
              >
                {title.regular}
              </SmartText>
              <SmartText
                fontFamily={theme.fonts.font_family_wide}
                fontWeightMob={theme.fonts.font_weights.medium}
                fontSizeMob={theme.fonts.font_sizes.header.md}
                fontSizeTablet={theme.fonts.font_sizes.header.h1}
                lineHeightMob={theme.fonts.line_heights.header.sm1}
                lineHeightTablet={theme.fonts.line_heights.header.lg}
                as="span"
              >
                {title.small}
              </SmartText>
            </>
          )

        return (
          <div key={id}>
            <Styled.Title>{convertedTitle}</Styled.Title>
            <Styled.AdvantageDescription
              fontFamily={theme.fonts.font_family_comp}
              fontSizeMob={theme.fonts.font_sizes.par.c1}
              fontSizeTablet={theme.fonts.font_sizes.par.lg}
              lineHeightTablet={theme.fonts.line_heights.par.lg}
              lineHeightMob={theme.fonts.line_heights.par.c1}
            >
              {description}
            </Styled.AdvantageDescription>
          </div>
        )
      })}
    </Styled.Wrapper>
  </PremiumBlock>
)
