import React, { FC, useEffect, useMemo } from 'react'
import { useSwiperSlide } from 'swiper/react'
import { Frame } from '@mtsdengi/ui-kit'
import { THomeCarouselSlider } from '../types'
import { Styled } from '../styled'

export const HomeCarouselSlide: FC<THomeCarouselSlider> = ({
  onChangeSlide,
  onClickButton,
  id,
  theme,
  ...slideProps
}) => {
  const { isActive } = useSwiperSlide()
  const activeSlideInfoProps = useMemo(
    () => ({
      headingText: slideProps.heading.text,
      buttonText: slideProps.button.text,
      position: slideProps.position,
      id,
    }),
    [id, slideProps.button.text, slideProps.heading.text, slideProps.position]
  )

  useEffect(() => {
    if (isActive) {
      onChangeSlide?.(activeSlideInfoProps)
    }
  }, [isActive, onChangeSlide, activeSlideInfoProps])

  return (
    <Frame theme={theme}>
      <Styled.FrameBase>
        <Styled.SlideCard isFullHeight {...slideProps} onClickButton={() => onClickButton(activeSlideInfoProps)} />
      </Styled.FrameBase>
    </Frame>
  )
}
