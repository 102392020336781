import styled, { css } from 'styled-components'
import 'swiper/css'
import { FrameBaseBg as FrameBaseBgKit, theme } from '@mtsdengi/ui-kit'
import { CardFeature } from '@mtsdengi/common'
import { THomeCarouselSlider } from './types'

const SlideCard = styled(CardFeature)<Pick<THomeCarouselSlider, 'slideGradientUrl'>>`
  ${({ slideGradientUrl }) =>
    slideGradientUrl &&
    css`
      background-image: url(${slideGradientUrl});
      background-size: cover;
      background-position: top center;
    `};
`

const FrameBase = styled(FrameBaseBgKit)`
  z-index: ${theme.z_indexes.basic};
`

export const Styled = {
  SlideCard,
  FrameBase,
}
